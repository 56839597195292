<template>
    <div class="check-box" @click="active=!active">
        <img src="@/assets/icons/white-check.svg" v-if="active" alt="">
    </div>
</template>
<script> 

export default  {
    props:['value'],
    data() {
        return{
            active:false
        }    
    },
         watch: {
         value: {
             handler(after) {
                 this.active = after;
             },
             immediate: true,
         },
         active(){  
            this.$emit('input', this.active);
         }
     },
}
</script>

<style lang="scss">
.check-box{
    width: 20px;
    height: 20px;
    border: 1.5px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        cursor: pointer;
    }
     
}
@media screen and(max-width:768px) {
    .check-box{
    width: 18px;
    height: 18px;
    border: 1.5px solid #DDDFE2;
    box-sizing: border-box;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 2px;
    justify-content: center;
    img{
        cursor: pointer;
        filter: invert(27%) sepia(7%) saturate(800%) hue-rotate(188deg) brightness(89%) contrast(85%);
    }
     
}
}
</style>