<template>
<div class="my-credit" >
    <div class="table-body table-my-credit desktop">
        <table>
            <thead>
                <tr>
                    <th class="table-th">№</th>
                    <th class="table-th">Дата відкриття</th>
                    <th class="table-th">Ставка по кредиту</th>
                    <th class="table-th">Сума відсотків</th>
                    <th class="table-th">Загальний платіж</th>
                    <th class="table-th">Сума кредиту</th>
                    <th class="table-th">Додатково сплачено</th>
                    <th class="table-th">Основний баланс</th>
                    <th class="table-th">Відкладені відсотки</th>
                    <th class="table-th">Загальнийборг</th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="i in 10" :key="i">
                    <td class="table-td"> {{i}} </td>
                    <td class="table-td">20.02.2021</td>
                    <td class="table-td">5.00%</td>
                    <td class="table-td">3, 550.30</td>
                    <td class="table-td c-blue">23 550 50 грн</td>
                    <td class="table-td ">-</td>
                    <td class="table-td ">-</td>
                    <td class="table-td">54 550.30</td>
                    <td class="table-td ">0.00</td>
                    <td class="table-td c-red"> 23 550 50 грн </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="table-cerdit-phone">
        <div class="table-phone-count">
            <div class="flex items-center">
                <p>Результатів на сторінку</p>
                <select name="A" id="a" v-model="count">
                    <option v-for="i in counts" :key="i" :value="i">{{i}} </option>
                </select>
            </div>
        </div>
        <div class="table-phone-items" v-for="i in count" :key="i" :class="i%2==0?'bg-dark-white':''">
            <div class="table-phone-item">
                <div class="table-phone-item-left">
                    <p class="thead-p">№</p>
                    <p class="tbody-p"> {{i}} </p>
                </div>
                <div class="table-phone-item-right">
                    <p class="thead-p">Дата</p>
                    <p class="tbody-p">20.10.2020</p>
                </div>
            </div>
            <div class="table-phone-item">
                <div class="table-phone-item-left">
                    <p class="thead-p">Ставка по кредиту</p>
                    <p class="tbody-p">5.00%</p>
                </div>
                <div class="table-phone-item-right">
                    <p class="thead-p">Сума відсотків</p>
                    <p class="tbody-p">3, 550.30</p>
                </div>
            </div>
            <div class="table-phone-item">
                <div class="table-phone-item-left">
                    <p class="thead-p">Загальний платіж</p>
                    <p class="tbody-p c-blue">23 550 50 грн</p>
                </div>
                <div class="table-phone-item-right">
                    <p class="thead-p">Сума про кредиту</p>
                    <p class="tbody-p  ">-</p>
                </div>
            </div>
            <div class="table-phone-item">
                <div class="table-phone-item-left">
                    <p class="thead-p">Додатково сплачено</p>
                    <p class="tbody-p">-</p>
                </div>
                <div class="table-phone-item-right">
                    <p class="thead-p">Основний баланс</p>
                    <p class="tbody-p">54 550.30</p>
                </div>
            </div> 
            <div class="table-phone-item">
                <div class="table-phone-item-left">
                    <p class="thead-p">Відкладені відсотки</p>
                    <p class="tbody-p">0.00</p>
                </div>
                <div class="table-phone-item-right">
                    <p class="thead-p">Загальний борг</p>
                    <p class="tbody-p c-red">23 550 50 грн</p>
                </div>
            </div> 
        </div>
    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            value: '',
            count:3,
            counts:[3,5,8,10],
            tableData: [{
                    date: '2016-05-03',
                    percent: '5%',
                    amount_interest: '3, 550.30',
                    loan_amount: '23 550 50 грн',
                    paid: '5 550 грн',
                    remainder: '5 550 грн',
                    bail: 'Ні',
                    status: 'Активний'
                },

            ]
        }
    }
}
</script>

<style lang="scss">
.my-credit {
    padding: 20px 0px;

}

.table-body {
    width: 100%;
    overflow: hidden;
}

.c-red {
    color: #FF4949 !important;
}

.c-green {
    color: #00E023 !important;
}

.c-blue {
    color: #00B5EC !important;
}
.bg-dark-white{
    background: #F8F8F9 !important;
}
.status-p{
    padding: 4px 10px;
    font-family: Stem-Regular;
    font-size: 1rem;
    line-height: 1.125rem; 
    color: #00E023;
    background: rgba(0, 224, 35, 0.1);
    border-radius: 4px;
    margin-top: 10px;
    max-width: 110px;
    text-align: center;
}

.table-my-credit {

    table,
    th,
    td,
    tr {
        border-collapse: collapse;
    }

    overflow: auto;

    table {
        min-width: 1000px;
    }

    table {
        width: 100%;
    }

    thead tr {
        background: #00B5EC;
    }

    tbody tr {
        border-bottom: 1.5px solid #DDDFE2;
    }

    .table-th {
        padding: 20px 5px;
        font-family: Stem-Bold;
        font-size: 1rem;
        line-height: 1.125rem;
        text-align: center;
        color: var(--white-color);
    }

    .table-td {
        font-family: Stem-Regular;
        font-size: 0.875rem;
        line-height: 1rem;
        text-align: center;
        color: var(--white-black);
        padding: 20px 5px;
    }

    .table-td:nth-child(1),
    .table-th:nth-child(1) {
        padding-left: 80px;
    }

    .table-td:last-child,
    .table-th:last-child {
        padding-right: 80px;
    }

    .table-button {
        padding: 8px 20px;
        background: rgba(192, 189, 189, 0.3);
        border-radius: 4px;
        font-family: Stem-Regular;
        font-size: 1rem;
        line-height: 1.125rem;
        color: var(--white-black);
        border: none;
        cursor: pointer;
        outline: none;
    }

}
.table-cerdit-phone{display: none;}
.table-phone-count {
    display: flex;
    justify-content: flex-end;
    padding: 0px 20px;
    padding-bottom: 20px;
    border-bottom: 1.5px solid #DDDFE2;

    select {
        outline: none !important;
        border: 1.5px solid #DDDFE2;
        box-sizing: border-box;
        border-radius: 4px;
        font-family: Stem-Medium;
        font-size: 0.875rem;
        line-height: 1rem;
        color: var(--other-black);
        padding: 3px;
        margin-left: 10px;
        width: 50px;

        option {
            padding: 5px;
            border: 1.5px solid #DDDFE2;
        }

    }
}

.table-phone-items {
    padding: 10px 20px;
    border-bottom: 1.5px solid #DDDFE2;

    .table-phone-item {
        display: flex;
        padding: 10px 0px;

        .table-phone-item-left {
            width: 50%;
            padding-right: 20px;
        }

        .table-phone-item-right {
            width: 50%;
            padding-left: 20px;
        }
    }
    .table-phone-button{
        padding: 3px 10px 4px 10px;
        background: #ECEBEB;
        border-radius: 4px;
        border:none;
        outline: none;
        cursor: pointer;
        font-family: Stem-Regular;
        font-size: 1rem;
        line-height: 1.125rem; 
        color: var(--other-black);
        margin-top: 10px;


    }

    .thead-p {
        font-family: Stem-Medium;
        font-size: 0.875rem;
        line-height: 1rem;
        color: rgba(69, 72, 81, 0.4);
    }

    .tbody-p {
        font-family: Stem-Regular;
        font-size: 1rem;
        line-height: 1.125rem;
        margin-top: 10px;
        color: var(--white-black);

    }

}

@media screen and(max-width:1100px) {
    .table-my-credit {

        .table-td:nth-child(1),
        .table-th:nth-child(1) {
            padding-left: 20px;
        }

        .table-td:last-child,
        .table-th:last-child {
            padding-right: 20px;
        }

    }

}

@media screen and(max-width:768px) {
    .table-my-credit {
        display: none;
    }
    .table-cerdit-phone{display: block;}
}
</style>
