<template>
<div class="flex justify-center w-full">
<div class="cabinet pt-150 w-full mw-1920">
    <Tab class="cabinet-tab" v-model="activeName" />
    <Information  v-if="$route.path=='/me'" />
    <MyCredit v-if="$route.path=='/me/credit'" />
    <Paymenthistory v-if="$route.path=='/me/history'"  />
    <Messages v-if="$route.path=='/me/message'"  />
    <ShowMessage v-if="$route.name=='show-message'"  />
</div>
</div>
</template>

<script>
 import Tab from '../../components/tab.vue'
 import Information from '../../components/Cabinet/Information.vue'
 import Messages from '../../components/Cabinet/Messages.vue'
 import MyCredit from '../../components/Cabinet/MyCredit.vue'
 import Paymenthistory from '../../components/Cabinet/PaymentHistory.vue'
 import ShowMessage from '../../components/Cabinet/ShowMessage.vue'
 export default {
     components: {
         Tab,
         Information,
         Messages,
         MyCredit,
         Paymenthistory,
         ShowMessage
     },
     data() {
         return {
             activeName: 1,
             input: ''
         }
     },
     computed:{
         me(){return this.$store.state.me}
     },
     watch:{
         activeName(val){ 
            if(val==1 && this.$route.path!='/me'){
                this.$router.push('/me') 
             }
             if(val==2 &&this.$route.path!='/me/credit'){
                this.$router.push('/me/credit') 
             }
             if(val==3&& this.$route.path!='/me/history'){
                this.$router.push('/me/history') 
             }
             if(val==4 && this.$route.path!='/me/message' && !this.$route.params.id ){
                this.$router.push('/me/message') 
             }
             if(val==4 && this.$route.name!='show-message' && this.$route.params.id ){
                this.$router.push(`/me/message/${this.$route.params.id}`) 
             }
             
         },
         '$route.path'(val){
             if(val=='/me'){
                this.activeName=1
             }
             if(val=='/me/credit'){
                this.activeName=2
             }
             if(val=='/me/history'){
                this.activeName=3
             }
             if(val=='/me/message'){
                this.activeName=4 
             } 
         } 
     },
     mounted(){
        
        if(this.$route.path=='/me'){ this.activeName=1}
        if(this.$route.path=='/me/credit'){ this.activeName=2}
        if(this.$route.path=='/me/history'){ this.activeName=3}
        if(this.$route.path=='/me/message'){ this.activeName=4}
        if(this.$route.name=='show-message'){ this.activeName=4}
     }
 }
</script>

<style>
.cabinet-tab { margin-top: 80px; }
.cabinet{
    background: var(--bg-top);
}

</style>
