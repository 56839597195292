<template>
<div class="messages owf-h"  >
    <div class="messages-header">
        <button class="message-back" @click="$router.push('/me/message')"> <img src="@/assets/icons/pre-white.svg" alt=""> {{$t('back')}} </button>
    </div>

    <div class="show-messages-item">
        <h1>Обновлення у договорі кредитування, зниження ставки та відповідальності за невчасне погашення</h1>
        <h2>Вчора о 20:21</h2>
        <p>Amet vel ipsum porttitor vel sed in nunc, id. Phasellus feugiat semper sit mauris tempor, risus in. Sed mauris nullam vestibulum dui molestie vivamus. Quis maecenas suspendisse massa sed. Ipsum neque lacus varius tempus viverra enim viverra nunc. Ut scelerisque aliquet quisque sit massa lacus varius.
            Pulvinar commodo, et enim, diam sed vel sit orci. Neque, purus tellus tempor, in hac amet. Odio leo, nisl enim purus platea ultricies viverra eget nullam. Felis velit nisi vitae, orci. Interdum fames feugiat nunc, vestibulum nulla accumsan donec. Sed adipiscing tristique convallis volutpat purus eget sed arcu viverra. Feugiat habitasse rhoncus nullam nisl eu at ac. Vulputate eleifend lectus bibendum ipsum nam ut. Consequat bibendum eu diam tortor, faucibus accumsan lacus auctor. Eget sollicitudin dignissim dui tellus urna. A est mauris pellentesque quis feugiat integer accumsan platea integer.Arcu volutpat senectus etiam leo. Morbi malesuada ultrices dolor sit. Sem elit facilisi quam sem. Consectetur sed tristique lectus amet, morbi tellus tincidunt. Tortor proin netus et nam molestie ut id</p>
    </div>
</div>
</template>

<script>
  export default {

      data() {
          return {
              active: '',
              checked: false,
              massages: [{
                      type: 'simple',
                      date: 'Вчора о 12:30',
                      text: 'Interdum sed orci luctus et convallis eleifend duis. Sed lectus morbi vel metus massa. '
                  },
                  {
                      type: 'unread',
                      date: 'Вчора о 12:30',
                      text: 'Interdum sed orci luctus et convallis eleifend duis. Sed lectus morbi vel metus massa. Convallis ut a ac pharetra non felis'
                  },
                  {
                      type: 'important',
                      date: 'Вчора о 12:30',
                      text: 'Interdum sed orci luctus et convallis eleifend duis. Sed lectus morbi vel metus massa. Convallis ut a ac pharetra non felis'
                  },
                  {
                      type: 'important',
                      date: 'Вчора о 12:30',
                      text: 'Interdum sed orci luctus et convallis eleifend duis. Sed lectus morbi vel metus massa. Convallis ut a ac pharetra non felis'
                  },
              ]
          }
      }
  }
</script>

<style lang="scss" scoped>
.messages {
    padding: 20px 0px 40px 0px;
}

.messages-header {
    width: 100%;
    padding: 25px 120px;
    background: #00B5EC;
    color: var(--white-color);
    display: flex;
    align-items: center;

    .messages-header-items {
        display: flex;
        align-items: center;
        margin-right: 60px;

        p {
            margin-left: 10px;
            font-family: Stem-Medium;
            font-size: 1rem;
            line-height: 1.125rem;
            text-align: center;
            color: var(--white-color);
            cursor: pointer;
        }
    }
}

.message-back {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    border: none;
    outline: none;
    padding: 12px 30px;
    font-family: Stem-Regular;
    font-size: 1rem;
    line-height: 1.125rem;
    color: var(--white-color);
    cursor: pointer;

    img {
        margin-right: 5px;
        width: 8px;
    }
}

.show-messages-item {
    padding: 60px 120px;

    h1 {
        font-family: Stem-Medium;
        font-size: 24px;
        line-height: 26px;
        font-feature-settings: 'tnum'on, 'lnum'on;
        color: var(--other-black);
    }

    h2 {
        font-family: Stem-Medium;
        font-size: 1.125rem;
        line-height: 1.25rem;
        color: var(--white-black);
        margin-top: 20px;
    }

    p {
        font-family: Stem-Regular;
        font-size: 1rem;
        line-height: 24px;
        color: var(--other-black);
        margin-top: 40px;
    }
}

@media screen and(max-width:1100px) {
    .messages-header {
        padding: 20px 20px;

    }

    .show-messages-item {
        padding: 60px 20px;
    }

}

@media screen and(max-width:768px) {
    .messages {
        padding: 10px 0px 40px 0px;
    }

    .messages-header {
        background: var(--white-bg);
    }

    .message-back {

        background: #00B5EC;
    }

    .show-messages-item {
        padding: 20px 20px;

        h1 {
            font-family: Stem-Medium;
            font-size: 1.125rem;
            line-height: 22px;
            font-feature-settings: 'tnum'on, 'lnum'on;
            color: var(--other-black);
        }

        h2 {
            font-family: Stem-Medium;
            font-size: 1rem;
            line-height: 1.25rem;
        }

        p {
            font-family: Stem-Regular;
            font-size: 0.875rem;
            line-height: 1.125rem;
        }
    }

}
</style>
