<template>
    <div class="messages owf-h"  >
        <div class="messages-header">
            <div class="messages-header-items" >
                <check v-model="checked" /><p @click="checked=!checked" class="desktop">Всього сповіщень: 4</p> <p @click="checked=!checked" class="phone">Всі</p>
            </div>
            <div class="messages-header-items" >
                <check v-model="checked" /><p @click="checked=!checked" class="desktop">Непрочитані : 2 </p> <p @click="checked=!checked" class="phone">Непрочитані</p>
            </div>
            <div class="messages-header-items" >
                <check v-model="checked" /><p @click="checked=!checked" class="desktop">Важливі : 2</p> <p @click="checked=!checked" class="phone">Важливі </p>
            </div>
              
        </div>
        <div class="messages-item" v-for="(item, i) in massages" :key="i" @click="$router.push(`/me/message/${i}`)" :class="item.type=='unread'?'bg-white-blue':item.type=='important'?'bg-white-red':''">
            <div class="messages-text">
                <p>{{item.text}} </p>
            </div>
            <div class="messages-time">
                <p>{{item.date}}</p> 
             </div>
        </div>
    </div>
</template>
<script> 
import check from '../check.vue'
export default  {
    components:{
        check
    },
    data() {
        return{
            active:'',
            checked:false,
            massages:[
                { type:'simple', date:'Вчора о 12:30', text:'Interdum sed orci luctus et convallis eleifend duis. Sed lectus morbi vel metus massa. ' },
                { type:'unread', date:'Вчора о 12:30', text:'Interdum sed orci luctus et convallis eleifend duis. Sed lectus morbi vel metus massa. Convallis ut a ac pharetra non felis' },
                { type:'important', date:'Вчора о 12:30', text:'Interdum sed orci luctus et convallis eleifend duis. Sed lectus morbi vel metus massa. Convallis ut a ac pharetra non felis' },
                { type:'important', date:'Вчора о 12:30', text:'Interdum sed orci luctus et convallis eleifend duis. Sed lectus morbi vel metus massa. Convallis ut a ac pharetra non felis' },
            ]
        }
    }
}
</script>

<style lang="scss">
.messages{
    padding:20px 0px 40px 0px;
}
.messages-header{
    width: 100%;
    padding:25px 120px;
    background: #00B5EC;
    color: var(--white-color);
    display: flex;
    align-items: center;
    .messages-header-items{
        display: flex;
        align-items: center;
        margin-right: 60px;
        p{
            margin-left: 10px;
            font-family: Stem-Medium;
            font-size: 1rem;
            line-height: 1.125rem;
            text-align: center;
            color: var(--white-color);
            cursor: pointer;
        }
    }
}
.messages-item{
    padding:30px 120px;
    border-bottom: 1.5px solid #DDDFE2;
    display: flex;
    align-items: center;
    cursor: pointer;
    .messages-text{
        padding-right: 30px;
        flex:1;
        p{
            font-family: Stem-Regular;
            font-size: 0.875rem;
            line-height: 1rem;
            color: var(--white-black);
        }
    }
    .messages-time{
        width: 100px;
        p{
            font-family: Stem-Regular;
            font-size: 0.875rem;
            line-height: 1rem;
            text-align: center;
            color: var(--white-black);

        }
    }
}
.bg-white-blue{
    background: rgba(0, 181, 236, 0.1);
}
.bg-white-red{
    background: rgba(255, 73, 73, 0.1);
}
@media screen and(max-width:1100px) {
    .messages-header{ 
    padding:20px 20px;
   
}
.messages-item{
    padding:20px 20px; 
     
}
}
@media screen and(max-width:768px){
    .messages{padding: 10px 0px 40px 0px;}
    .messages-header{    
        background: var(--white-bg);
    .messages-header-items{
        display: flex;
        align-items: center;
        margin-right: 30px;
        p{
           font-size: 0.875rem;
            line-height: 1rem;
            color: var(--white-black);
        }
    }
}
.messages-item{  
    flex-wrap: wrap;
    .messages-text{
        padding-right: 0px;
        width: 100%;
        p{
            font-family: Stem-Regular;
            font-size: 0.875rem;
            line-height: 1rem;
            color: var(--white-black);
            width: 100%;
            overflow:hidden; 
            white-space:nowrap; 
            text-overflow: ellipsis;
        }
    }
    .messages-time{
        width: 100%;
        p{
           font-family: Stem-Regular;
            font-size: 11px;
            line-height: 13px;
            text-align: left;
            color: rgba(69, 72, 81, 0.6);
            margin-top: 10px;

        }
    }
}
}
</style>