<template>
<div class="tab">
    <div class="tab-desktop">
        <div class="flex">
            <div class="tab-item" @click="active=1">
                <p>Кабінет</p>
                <div v-if="active==1" class="tab-activer"></div>
            </div>
            <div class="tab-item" @click="active=2">
                <p>Мої кредити</p>
                <div v-if="active==2" class="tab-activer"></div>
            </div>
            <div class="tab-item" @click="active=3">
                <p>Історія оплат</p>
                <div v-if="active==3" class="tab-activer"></div>
            </div>
            <div class="tab-item" @click="active=4">
                <p class="message-p">Сповіщення</p>
                <div class="badge">4</div>
                <div v-if="active==4" class="tab-activer"></div>
            </div>

        </div>
        <div class="flex">
            <div class="tab-item" @click="logout()" >
                <p>Вихід</p> 
            </div>
        </div>
    </div>
    <div class="tab-phone">
        <div class="tab-phone-title" @click="tab=!tab">
            <p> {{tabNames[active-1]}} </p>
            <img src="@/assets/icons/down.svg" :class="tab?'rotate-180':''" alt="">
        </div>
        <el-collapse-transition>
            <div class="tab-items" v-show="tab">
                <div v-for="(item, i) in tabNames" :key="i" @click="active=i+1; tab=false"  :class="active==i+1?'display-none':''">
                    <p> {{item}} </p>
                </div> 
            </div>
        </el-collapse-transition>
    </div>
</div>
</template>

<script>
export default {
    props:['value'],
    data() {
        return {
            active: 1,
            tabNames:['Кабінет', 'Мої кредити', 'Історія оплат',  'Сповіщення'],
            tab: false
        }
    },
    watch:{
        value:{
            handler(after) {
                 this.active = after;
             },
             immediate: true,
        },
        active(){
            this.$emit('input', this.active);
        }
    },
    methods: {
        a() {
            console.log('AA')
        },
        logout() {
            this.$store.commit('get_me', {
                payload: {}
            })
            localStorage.month=null
            localStorage.count=null
            localStorage.token = null
            localStorage.role = null
            this.$router.push('/login')
        },
    }
}
</script>

<style lang="scss" scoped>
.tab {
    width: 100%;
    padding: 0px 110px;
    border-bottom: 6px solid #DDDFE2;

}

.tab-desktop {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.tab-item {
    padding-bottom: 27px;
    padding-top: 10px;
    margin-right: 80px;
    position: relative;
    cursor: pointer;

    p {
        font-family: Stem-Medium;
        font-size: 20px;
        line-height: 1.5rem;
        display: flex;
        align-items: center;
        margin: 0;
        color: var(--other-black);
    }

    .tab-activer {
        position: absolute;
        bottom: -6px;
        width: 100%;
        height: 6px;
        background: var(--black);
        left: 0;
        border-radius: 10px;
    }

    .badge {
        position: absolute;
        top: 5px;
        right: 0;
        font-family: Stem-Medium;
        font-size: 20px;
        line-height: 1.5rem;
        display: flex;
        align-items: center;
        color: #FF4949;
    }

    .message-p {
        margin-right: 20px;
    }
}
.tab-phone{display: none;}
@media screen and(max-width:1100px) {
    .tab {
        padding: 0px 20px;
    }
}

@media screen and(max-width:950px) {
    .tab-item {
        margin-right: 40px;
    }

}

@media screen and(max-width:768px) {
    .tab {
        padding: 0px;
        border-bottom: 2px solid #DDDFE2;
    }

    .tab-desktop {
        display: none;
    }

    .tab-phone {
        width: 100%;
    }

    .title-tab {
        font-family: Stem-Medium;
        font-size: 1.125rem;
        line-height: 1.25rem;
        color: var(--other-black);
    }

    .tab-phone {
        display: block;
        .tab-phone-title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 20px 20px;
            cursor: pointer;
        }

        p {
            font-family: Stem-Medium;
            font-size: 1.125rem;
            line-height: 1.25rem;
            color: var(--other-black);
        }

        .tab-items {
            transition: all .2s ease-in;
            width: 100%;

            div {
                padding: 20px;
                background: rgba(196, 196, 196, 0.1);
                cursor: pointer;
            }

        }

        img {
            transition: all .2s ease-in;
        }

        .rotate-180 {
            transform: rotate(180deg); 
        }

    }

}
</style>
