<template>
<div class="information owf-h" v-if="editpassword">
    <h1> {{$t('information')}} </h1>
    <div class="flex mt-20px">
        <button class="osoba-button"> {{$t('fiz_osoba')}} </button>
        <button class="restore-password" @click="editpassword=false">{{$t('change_password')}}</button>
    </div>
    <div class="information-inputs mt-40px">
        <div class=" information-input information-left">
            <div class="input-div">
                {{information.f_name ? information.f_name :'*'}}
            </div> 
            <div class="input-div">
                {{information.l_name ? information.l_name :'*'}}
            </div> 
            <div class="input-div">
                {{information.s_name ? information.s_name :'*'}}
            </div> 
            <div class="input-div">
                {{information.email ? information.email :'*'}}
            </div> 
            <div class="input-div">
                {{information.phone ? information.phone :'*'}}
            </div> 
            <div class="input-div">
                {{information.birthdate ? information.birthdate :'*'}}
            </div>  
        </div>
        <div class=" information-input information-right">
            <div class="input-div">
                {{information.passport_serial ? information.passport_serial :'*'}}
            </div> 
            <div class="input-div">
                {{information.issued_by ? information.issued_by :'*'}}
            </div> 
            <div class="input-div">
                {{information.issued_date ? information.issued_date :'*'}}
            </div> 
            <div class="input-div">
                {{information.identification_number ? information.identification_number :'*'}}
            </div> 
            <div class="input-div">
                {{information.street ? information.street :'*'}}
            </div> 
            <div class="input-div">
                {{information.address ? information.address :'*'}}
            </div>  
        </div>
    </div>  
</div>
<div class="information owf-h" v-else>
    <h1> {{$t('change_password')}} </h1>
    <div class="flex mt-20px mb-20px">
        <button class="button gray" @click="editpassword=true"> <img src="@/assets/icons/pre.svg" class="mr-10" alt=""> {{$t('back')}} </button>
    </div>
    <div class="edit-password">

        <div class="edit-passord-item">
            <el-form :model="passwordEdit" :rules="passwordEditRules" ref="passwordEdit">
                <el-form-item prop="old_password">
                    <el-input placeholder="Актуальний пароль*" type="password" class="edit-password-input" v-model="passwordEdit.old_password" show-password></el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input placeholder="Новий пароль*" type="password" class="edit-password-input" v-model="passwordEdit.password" show-password></el-input>
                </el-form-item>
                <el-form-item prop="verify_password">
                    <el-input placeholder="Повторіть пароль*" type="password" class="edit-password-input" v-model="passwordEdit.verify_password" show-password></el-input>
                </el-form-item>
                <div class="flex chenge-password-button">
                    <button class="button" @click="Edit" type="button">Зберегти</button>
                </div>
            </el-form>
        </div>
        <div class="edit-passord-item">
            <img src="@/assets/img/white-logo.svg" alt="">
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        let reg = /(?!^ (\d +|[a-zA-Z]+ | [~!@#$%^&*?]+ )$ )^[\w~!@#$%^&*?]{6,32}$/
        var validateNewPwd = (rule, value, callback) => {
            if (!reg.test(value)) {
                callback(new Error(this.$t('password_len')))
            } else {
                callback()
            }
        }
        var validateComfirmPwd = (rule, value, callback) => {
            if (!reg.test(value)) {
                callback(new Error(this.$t('password_len')))
            } else if (this.passwordEdit.verify_password !== this.passwordEdit.password) {
                callback(new Error('The confirmation password is different from the new password.'))
            } else {
                callback()
            }
        }
        return {
            editpassword: true,
            input: '',
            information: {
                "f_name": null,
                "l_name": null,
                "s_name": null,
                "phone": 998998780787,
                "birthdate": null,
                "passport_serial": null,
                "issued_by": null,
                "issued_date": null,
                "identification_number": null,
                "address": null,
                "street": null,
                "email": "admin@admin.com",
            },
            passwordEdit: {
                old_password: '',
                password: '',
                verify_password: ''
            },
            passwordEditRules: {
                old_password: [{
                    required: true,
                    message: this.$t('requier_input'),
                    trigger: 'blur'
                }, {
                    validator: validateNewPwd,
                    trigger: 'blur'
                }],
                password: [{
                    required: true,
                    message: this.$t('requier_input'),
                    trigger: 'blur'
                }, {
                    validator: validateNewPwd,
                    trigger: 'blur'
                }],
                verify_password: [{
                    required: true,
                    message: this.$t('requier_input'),
                    trigger: 'blur'
                }, {
                    validator: validateComfirmPwd,
                    trigger: 'blur'
                }],
            }
        }
    },
    computed: {
        me() {
            return this.$store.state.me
        }
    },
    watch: {
        me() {
            this.information = this.me
        }
    },
    methods: {
        Edit() {
            this.$refs.passwordEdit.validate((valid) => {
                if (valid) {
                    this.$notify({
                        title: 'Пароль изменен',
                        message: 'Ваш пароль был успешно изменен',
                        type: 'success'
                    });
                    this.$refs.passwordEdit.resetFields();
                    this.$router.push('/')
                }
            })
        }
    },
    mounted() {
        this.information = this.me
    }
}
</script>

<style lang="scss">
.information {
    padding: 60px 110px;
    background: var(--white-bg);

    h1 {
        font-family: Stem-Medium;
        font-size: 30px;
        line-height: 35px;
        display: flex;
        align-items: center;
        color: var(--black);
    }

    .information-left {
        padding-right: 30px;
        width: 50%;
    }

    .information-right {
        padding-left: 30px;
        width: 50%;
    }

    .information-inputs {
        display: flex;
        flex-wrap: wrap;
    }

    .information-input {
        .input-div {
            width: 100%;
            padding: 20px 0px;
            border: none;
            border-bottom: 1px solid #DDDFE2;
            font-family: Stem-Regular;
            font-size: 1.125rem;
            line-height: 1.25rem;
            color: var(--white-black);
            outline: none;

        }

        input:focus {
            border-bottom: 1px solid #00B5EC;
            color: var(--color-blue);
        }
    }
}

.osoba-button {
    background: none;
    border: none;
    outline: none;
    margin-right: 60px;
    font-family: Stem-Medium;
    font-size: 24px;
    line-height: 28px;
    color: var(--color-blue);
    padding: 0;
}

.restore-password {
    padding: 8px 26px;
    font-family: Stem-Regular;
    font-size: 1rem;
    line-height: 1.125rem;
    display: flex;
    align-items: center;
    color: var(--other-black);
    background: rgba(192, 189, 189, 0.3);
    border-radius: 4px;
    border: none;
    outline: none;
    cursor: pointer;
}

.edit-password {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .edit-passord-item {
        padding: 0px;
        width: 50%;

        .edit-password-input {
            max-width: 500px;
            width: 100%;
            margin-top: 0px;

            .el-input__inner {
                padding: 18px 30px;
                font-family: Stem-Regular;
                font-size: 1.125rem;
                line-height: 1.25rem;
                height: auto;
                color: var(--white-black);
                border: 1.5px solid #DDDFE2;
                box-sizing: border-box;
                border-radius: 4px;
            }
        }

        img {
            width: 80%;
        }
    }

    .edit-passord-item:nth-child(1) {
        padding-right: 30px;
    }

    .edit-passord-item:nth-child(2) {
        padding-left: 30px;
        display: flex;
        justify-content: center;
    }

    .chenge-password-button {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        max-width: 500px;
    }

}

@media screen and(max-width:1100px) {
    .information {
        padding: 50px 20px;
    }
}

@media screen and(max-width:768px) {
    .information {
        padding: 40px 20px;
        background: var(--white-bg);

        h1 {
            font-size: 24px;
            line-height: 28px;
        }

        .information-left {
            padding-right: 0px;
            width: 100%;
        }

        .information-right {
            padding-left: 0px;
            width: 100%;
        }

        .osoba-button {
            font-size: 0.875rem;
            line-height: 1rem;
            margin-right: 30px;
        }
    }

    .edit-password {

        .edit-passord-item {
            padding: 20px 0px;
            width: 100%;

            .edit-password-input {
                margin-top: 20px;

                .el-input__inner {
                    padding: 15px 20px;
                    font-size: 1rem;
                    line-height: 1.125rem;
                }
            }

            img {
                width: 90%;
            }
        }

        .edit-passord-item:nth-child(1) {
            padding-right: 0px;
        }

        .edit-passord-item:nth-child(2) {
            padding-left: 0px;
        }

    }

}
</style>
